import React from "react"
import logo from "../assets/logo.svg"
import { FaAlignJustify } from "react-icons/fa"
import PageLinks from "../constants/links"


const Navbar = ({ toggleSidebar }) => {
  return (
    <nav className="navbar">
      <div className="nav-center">
        <div className="nav-header">
          
          
          <button type="button" className="toggle-btn" onClick={toggleSidebar}>
            <FaAlignJustify></FaAlignJustify>
            {/* <span className="nav-toggle-menu">Menu</span> */}
          </button>
        </div>
        <PageLinks styleClass="nav-links"></PageLinks>
        
      </div>
    </nav>
  )
}

export default Navbar