import React from "react"
import SocialLinks from "../constants/socialLinks"
const Footer = () => {
  return (
    <footer className="footer">
      <div>
        <SocialLinks styleClass="footer-links"></SocialLinks>
        <span className="copyright-text">
          &copy; Designed By Ibad khan {new Date().getFullYear()} 
        </span>
      </div>
    </footer>
  )
}

export default Footer